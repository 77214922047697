import axios from 'axios';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import Layout from '../components/Layout';
import NotificationCard from '../components/NotificationCard';
import ReservoirCard from '../components/reservoirCard';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { colors } from '../utils/siteVars';

const StyledHomePage = styled.div`
  .customer-card-header-right-content {
    display: flex;
    gap: 0.5rem;
  }
  .search-box {
    position: relative;
    button {
      border: 1px solid ${colors.darkGrey};
      position: absolute;
      top: 0.3rem;
      right: 0.3rem;
    }
    input {
      min-width: 250px;
    }
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    a {
      margin-right: 1rem;
    }
  }
`;

const HomePage = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [customers, setCustomers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [reservoirsSearchString, setReservoirsSearchString] = useState('');
  const [searchReservoirsHelperText, setSearchReservoirsHelperText] = useState(
    'Sök för att visa reservoarer'
  );
  const [searchString, setSearchString] = useState('');
  const [matchingReservoirs, setMatchingReservoirs] = useState([]);
  const [
    totalNumberOfActiveNotifications,
    setTotalNumberOfActiveNotifications,
  ] = useState(0);

  const getOverviewData = (q) => {
    dispatch({ type: 'LOADING', value: true });
    axios
      .get(
        `${process.env.GATSBY_BACKEND_API_URL}customers/overviewdata/${
          state.user._id
        }/?q=${q || ''}`,
        {
          headers: { Authorization: 'Bearer ' + state.token },
        }
      )
      .then((res) => {
        setCustomers(res.data.customers);
        parseNotifications(res.data.notifications);
      })
      .catch(() => {
        dispatch({ type: 'ALERT', content: 'Något gick fel' });
        dispatch({ type: 'LOADING', value: false });
      });
  };

  const commitCustomerSearch = (e) => {
    e.preventDefault();
    if (searchString.length > 2) getOverviewData(searchString);
  };

  const parseNotifications = (data) => {
    const onlyActiveNotifications = data.filter((n) => !n.archived);
    const filteredMyActive = onlyActiveNotifications.sort((a, b) => {
      const dateA = new Date(a.dateForHandling);
      const dateB = new Date(b.dateForHandling);
      return dateA - dateB;
    });

    setTotalNumberOfActiveNotifications(onlyActiveNotifications.length);
    setNotifications(filteredMyActive.slice(0, 6));

    dispatch({ type: 'LOADING', value: false });
  };

  const searchReservoirs = (e) => {
    e.preventDefault();
    if (reservoirsSearchString.length < 3) {
      setSearchReservoirsHelperText('Skriv minst 3 tecken för att söka');
      return;
    }
    dispatch({ type: 'LOADING', value: true });
    axios
      .get(
        `${process.env.GATSBY_BACKEND_API_URL}customers/search-reservoirs/?q=${reservoirsSearchString}`,
        {
          headers: {
            Authorization: 'Bearer ' + state.token,
          },
        }
      )
      .then(({ data }) => {
        setMatchingReservoirs(data);
        dispatch({ type: 'LOADING', value: false });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: 'LOADING', value: false });
        dispatch({ type: 'ALERT', content: 'Något gick fel' });
      });
  };

  useEffect(() => {
    dispatch({ type: 'LOADING', value: true });
    if (state.token) {
      getOverviewData();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  return (
    <Layout>
      <StyledHomePage>
        <h1 className="page-header">Översikt</h1>
        <div className="single-customer-inner">
          <div className="single-customer-left-content">
            <Card>
              <div className="customer-card-header">
                <h3>Sök reservoarer</h3>
                <div className="customer-card-header-right-content">
                  <form onSubmit={searchReservoirs} className="search-box">
                    <input
                      type="text"
                      value={reservoirsSearchString}
                      onChange={(e) =>
                        setReservoirsSearchString(e.target.value)
                      }
                      placeholder="Sök reservoarer"
                    />
                    <Button
                      small
                      type="submit"
                      disabled={reservoirsSearchString.length < 3}
                    >
                      Sök
                    </Button>
                  </form>
                </div>
              </div>
              {searchReservoirsHelperText ? (
                <p>{searchReservoirsHelperText}</p>
              ) : null}
              {matchingReservoirs.map((reservoir) => (
                <ReservoirCard
                  inner
                  key={reservoir._id}
                  reservoir={reservoir}
                  customer={{ _id: reservoir.customerId }}
                />
              ))}
            </Card>
            <Card>
              <div className="customer-card-header">
                <div>
                  <h3>Kunder</h3>
                  <Link to="/customers">
                    <Button noBg>Visa alla</Button>
                  </Link>
                </div>
                <div className="customer-card-header-right-content">
                  <form onSubmit={commitCustomerSearch} className="search-box">
                    <input
                      type="text"
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                      placeholder="Sök kunder"
                    />
                    <Button
                      small
                      type="submit"
                      disabled={searchString.length < 3}
                    >
                      Sök
                    </Button>
                  </form>
                </div>
              </div>
              {customers.map((customer) => (
                <CustomerCard inner customer={customer} key={customer._id} />
              ))}
            </Card>
          </div>
          <div className="single-customer-right-content">
            <Card>
              <div className="customer-card-header">
                <h3>Notiser</h3>
                <div className="customer-card-header-right-content">
                  <Link to="/notifications">
                    <Button noBg>
                      Visa alla ({totalNumberOfActiveNotifications})
                    </Button>
                  </Link>
                </div>
              </div>
              {notifications.map((notification) => (
                <NotificationCard
                  noDelete
                  key={notification._id}
                  notification={notification}
                />
              ))}
            </Card>
          </div>
        </div>
      </StyledHomePage>
    </Layout>
  );
};

export default HomePage;
